import React, { useState } from 'react';
import { Modal, Typography, Box, Button } from '@mui/material';
import { useError } from '../../context/ErrorContext';
import { useNavigate } from 'react-router-dom';
import warningIcon from '../../assets/images/backgrounds/warning-icon.svg';

const ErrorModal = () => {
  const { error, clearError } = useError();
  const [showModal, setShowModal] = useState(null);
  const navigate = useNavigate();

  const handleCloseError = () => {
    setShowModal(false);
    clearError();
    navigate('/'); // Redirect on close
    window.location.reload(); // refresh page to get new session
  };

  return (
    <Modal
      open={Boolean(error)}
      onClose={handleCloseError} // Redirect when clicking outside
      closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 2,
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          minWidth: 300,
          justifyContent: 'space-between',
        }}>
        <Typography variant='h6' gutterBottom>
          Algo salió mal.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img src={warningIcon} alt='warning-icon' />
        </Box>
        <Typography variant='body1' gutterBottom>
          Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo más
          tarde.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant='contained' onClick={handleCloseError}>
            Volver al inicio
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
