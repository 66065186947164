const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // Check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );

    console.log('Attempting to load component...');
    console.log('Has refreshed:', hasRefreshed);

    // Try to import the component
    componentImport()
      .then((component) => {
        console.log('Component loaded successfully.');
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // Success, so reset the refresh flag
        resolve(component);
      })
      .catch((error) => {
        console.error('Error loading component:', error);

        if (!hasRefreshed) {
          console.log('Retrying by refreshing the page...');
          // Not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // Set the refresh flag
          return window.location.reload(); // Refresh the page
        }

        console.error('Failed to load component after refresh attempt.');
        reject(error); // Default error behavior as already tried refresh
      });
  });
};

export default lazyRetry;
