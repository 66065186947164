// create context and provider
import React, { createContext, useEffect, useState, useRef } from 'react';
import jwt from 'jwt-decode';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [pageReload, setPageReload] = useState(false);

  // Create refs for abort controllers
  const reportsControllerRef = useRef(new AbortController());
  const totalsControllerRef = useRef(new AbortController());
  const trafficControllerRef = useRef(new AbortController());

  const abortAllControllers = () => {
    reportsControllerRef.current?.abort();
    totalsControllerRef.current?.abort();
    trafficControllerRef.current?.abort();
  };

  useEffect(() => {
    return () => {
      abortAllControllers();
    };
  }, []);

  useEffect(() => {
    setUserData();
  }, []);

  function setUserData() {
    const token = localStorage.getItem('token');
    const success = localStorage.getItem('successStatus');
    const clientesjwt = localStorage.getItem('clientes');
    const permisosjwt = localStorage.getItem('permisos');
    if (success === 'true' && token && clientesjwt && permisosjwt) {
      try {
        jwt(token);
        jwt(permisosjwt);
        jwt(clientesjwt);
      } catch (error) {
        localStorage.clear();
        // navigate('/auth/login');
        window.location.reload(true);
      }
      const info = jwt(token);
      const clientes = jwt(clientesjwt);
      const permisos = jwt(permisosjwt);
      if (info?.user && clientes?.clientes) {
        const user = info.user;
        user.clientes = clientes.clientes;
        user.permisos = permisos.permisos;
        setUser(info.user);
      }
    } else {
      const url = window.location.href;
      if (!url.includes('quickdownload')) {
        localStorage.clear();
        // navigate('/auth/login');
      }
    }
  }

  // Provide the abort controller refs and functions to manage them
  return (
    <UserContext.Provider
      value={{
        user,
        setUserData,
        setUser,
        reportsControllerRef,
        totalsControllerRef,
        trafficControllerRef,
      }}>
      {props.children}
    </UserContext.Provider>
  );
};
