import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { configureStore } from './redux/Store';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Spinner from './views/spinner/Spinner';
import App from './App';

import { createRoot } from 'react-dom/client';

const root = document.getElementById('root');

createRoot(root).render(
  <Provider store={configureStore()}>
    <Suspense fallback={<Spinner />}>
      <HashRouter>
        <App />
      </HashRouter>
      {/* <EventListeners /> Add the EventListeners component */}
    </Suspense>
  </Provider>,
);
