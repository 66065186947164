import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { UserProvider, ErrorProvider } from './context/';
import ErrorPopup from './views/alert/ErrorPopup';
import styles from './styles/styles.css';

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <ErrorProvider>
          <UserProvider>
            <CssBaseline className={styles} />
            {routing}
          </UserProvider>
          <ErrorPopup />
        </ErrorProvider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
