import _ from 'lodash';
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import typography from './Typography';
import components from './Override';
import shadows from './Shadows';

// ##############################import {
import { MAIN_THEME, DARK_THEME } from '../../redux/constants';
// // // Global Variables
// ##############################

const SidebarWidth = 265;
const TopbarHeight = 70;

const baseTheme = {
  direction: 'ltr',
  palette: {
    primary: {
      main: '#1a97f5',
      light: '#e6f4ff',
      dark: '#1682d4',
    },
    secondary: {
      main: '#1e4db7',
      light: '#ddebff',
      dark: '#173f98',
    },

    success: {
      main: '#00c292',
      light: '#ebfaf2',
      dark: '#00964b',
      contrastText: '#ffffff',
    },
    danger: {
      main: '#ff2a8b',
      light: '#ffdeea',
    },
    info: {
      main: '#0bb2fb',
      light: '#E8FEFF',
      dark: '#a1caff',
    },
    error: {
      main: '#e46a76',
      light: '#fdf3f5',
      dark: '#e45a68',
    },
    warning: {
      light: '#FFFAE5',
      main: '#fec90f',
      dark: '#dcb014',
      contrastText: '#ffffff',
    },
    cancel: {
      main: '#969b9b',
      light: '#e1e8e8',
    },
    text: {
      secondary: '#777e89',
      danger: '#fc4b6c',
    },
    grey: {
      A100: '#ecf0f2',
      A200: '#99abb4',
      A400: '#767e89',
      A700: '#e6f4ff',
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: 'rgba(0, 0, 0, 0.03)',
    },
  },

  shape: {
    borderRadius: 5,
  },
  mixins: {
    toolbar: {
      color: '#949db2',
      '@media(min-width:1280px)': {
        minHeight: TopbarHeight,
        padding: '0 30px',
      },
      '@media(max-width:1280px)': {
        minHeight: '64px',
      },
    },
  },
  status: {
    danger: '#e53e3e',
  },
  components,
  typography,
  shadows,
};

const themesOptions = [
  {
    name: MAIN_THEME,
    palette: {
      mode: 'light',
      primary: {
        main: '#262626',
        light: '#e5fafb',
        dark: '#fda997',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#FFB9A7',
        light: '#fcf1ed',
        dark: '#e67e5f',
        contrastText: '#080808',
      },
      background: {
        default: '#fafbfb',
        dark: '#ffffff',
        paper: '#ffffff',
      },
      text: {
        primary: '#000000de',
        secondary: '#777e89',
      },
    },
  },
  {
    name: DARK_THEME,
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffffff',
        light: '#fafbfb',
        dark: '#e67e5f',
        contrastText: '#000000de',
      },
      secondary: {
        main: '#FFB9A7',
        light: '#fcf1ed',
        dark: '#e67e5f',
        contrastText: '#000000',
      },
      background: {
        default: '#1c222e',
        dark: '#1c2025',
        paper: '#353a42',
      },
      text: {
        primary: '#fafbfb',
        secondary: '#e6e5e8',
      },
      // success: {
      //   main: '#ebfaf2',
      //   light: '#00c292',
      //   dark: '#00964b',
      //   contrastText: '#ffffff',
      // },
      // danger: {
      //   main: '#ffdeea',
      //   light: '#ff2a8b',
      // },
      // info: {
      //   main: '#E8FEFF',
      //   light: '#0bb2fb',
      //   dark: '#cfddff',
      // },
      // error: {
      //   main: '#fdf3f5',
      //   light: '#e46a76',
      //   dark: '#e45a68',
      // },
      // warning: {
      //   light: '#fec90f',
      //   main: '#FFFAE5',
      //   dark: '#dcb014',
      //   contrastText: '#ffffff',
      // },
      // cancel: {
      //   main: '#e1e8e8',
      //   light: '#969b9b',
      // },
    },
  },
];

export const BuildTheme = (config = {}) => {
  const customizer = useSelector((state) => state.CustomizerReducer);

  const currentTheme = themesOptions.find(
    (theme) => theme.name === config.theme,
  );
  const darkTheme = themesOptions.find((theme) => theme.name === DARK_THEME);

  // light or dark theme
  const activeMode = customizer.activeMode;

  // change themeOptions according to theme mode
  let themeOptions = activeMode === 'dark' ? darkTheme : currentTheme;

  const baseMode = {
    palette: {
      mode: activeMode,
      background: {
        default: activeMode === 'dark' ? '#1c222e' : '#fafbfb',
        dark: activeMode === 'dark' ? '#1c2025' : '#ffffff',
        paper: activeMode === 'dark' ? '#282C34' : '#ffffff',
      },
      text: {
        primary: activeMode === 'dark' ? '#e6e5e8' : 'rgba(0, 0, 0, 0.87)',
        secondary: activeMode === 'dark' ? '#adb0bb' : '#777e89',
      },
    },
  };
  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  const theme = createTheme(
    _.merge({}, baseTheme, themeOptions, {
      direction: config.direction,
    }),
  );
  return theme;
};

export { TopbarHeight, SidebarWidth, baseTheme };
