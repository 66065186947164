import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import lazyRetry from '../utils/lazyRetry';

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() => lazyRetry(() => import('../layouts/full-layout/FullLayout'))),
);
const BlankLayout = Loadable(
  lazy(() => lazyRetry(() => import('../layouts/blank-layout/BlankLayout'))),
);

/* ***Error Views**** */
const Error = Loadable(
  lazy(() => lazyRetry(() => import('../views/authentication/Error'))),
);
const ErrorPopup = Loadable(
  lazy(() => lazyRetry(() => import('../views/alert/ErrorPopup'))),
);

/* ***Authentication**** */
const Login = Loadable(
  lazy(() => lazyRetry(() => import('../views/authentication/Login'))),
);
const Register = Loadable(
  lazy(() => lazyRetry(() => import('../views/authentication/Register'))),
);
const ResetPassword = Loadable(
  lazy(() => lazyRetry(() => import('../views/authentication/ResetPassword'))),
);
const NewPassword = Loadable(
  lazy(() => lazyRetry(() => import('../views/authentication/newPassword'))),
);
const MailSent = Loadable(
  lazy(() => lazyRetry(() => import('../views/authentication/MailSent'))),
);
const PasswordUpdated = Loadable(
  lazy(() =>
    lazyRetry(() => import('../views/authentication/PasswordUpdated')),
  ),
);

/* ****Dashboards***** */
const Dashboard = Loadable(
  lazy(() => lazyRetry(() => import('../views/dashboards/Dashboard'))),
);

/* **** Widget ***** */
const WidgetTable = Loadable(
  lazy(() => lazyRetry(() => import('../views/widgets/WidgetTable'))),
);
const WidgetEdit = Loadable(
  lazy(() => lazyRetry(() => import('../views/widgets/WidgetEdit'))),
);

/* ****Reports***** */
const IncomeReportDetail = Loadable(
  lazy(() => lazyRetry(() => import('../views/reports/IncomeReportDetails'))),
);
const TrafficReports = Loadable(
  lazy(() => lazyRetry(() => import('../views/reports/TrafficReports'))),
);
const Reports = Loadable(
  lazy(() => lazyRetry(() => import('../views/reports/ReportList'))),
);
const ReportView = Loadable(
  lazy(() => lazyRetry(() => import('../views/reports/ReportView'))),
);
const DownloadReport = Loadable(
  lazy(() => lazyRetry(() => import('../views/reports/DownloadReport'))),
);
const LogsView = Loadable(
  lazy(() => lazyRetry(() => import('../views/logs/LogsView'))),
);
const RiskEvaluation = Loadable(
  lazy(() => lazyRetry(() => import('../views/reports/RiskEvaluation'))),
);

/* ****Downloadables***** */
const Downloadables = Loadable(
  lazy(() =>
    lazyRetry(() => import('../views/downloadables/DownloadableView')),
  ),
);
const ResumeView = Loadable(
  lazy(() => lazyRetry(() => import('../views/downloadables/ResumeView'))),
);
const GenerateSummary = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../views/downloadables/components/GenerateSummary'),
    ),
  ),
);
const QuickDownload = Loadable(
  lazy(() => lazyRetry(() => import('../views/downloadables/QuickDownload'))),
);
const QuickReportView = Loadable(
  lazy(() => lazyRetry(() => import('../views/reports/QuickDownloadReport'))),
);
/* ****Factoring ***** */
const FactoringCompanies = Loadable(
  lazy(() => lazyRetry(() => import('../views/factoring/CompaniesList'))),
);
const FactoringCompanyView = Loadable(
  lazy(() => lazyRetry(() => import('../views/factoring/CompanyView'))),
);

/* ****Payments***** */
const PaymentsView = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../views/payments/payins-components/PaymentsListView'),
    ),
  ),
);
const PayoutsListView = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../views/payments/payouts-components/PayoutsListView'),
    ),
  ),
);
const PaymentsSettings = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../views/payments/widgets-components/PaymentWidgetList'),
    ),
  ),
);
const PaymentWidgetDetails = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../views/payments/widgets-components/WidgetDetails'),
    ),
  ),
);
const PaymentWidgetCreate = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../views/payments/widgets-components/WidgetCreate'),
    ),
  ),
);
const PaymentLinks = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../views/payments/paymentlink-components/PaymentsLinks'),
    ),
  ),
);
const PaymentStatsView = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../views/payments/stats-components/PaymentStatsView'),
    ),
  ),
);
const GenerateCollection = Loadable(
  lazy(
    () => import('../views/payments/paymentlink-components/GenerateCollection'),
  ),
);
const PayoutsBalance = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../views/payments/payouts-components/PayoutsBalance'),
    ),
  ),
);

/* ****Tokens***** */
const TokensTable = Loadable(
  lazy(() => lazyRetry(() => import('../views/tokens/Tokens'))),
);
const TokenView = Loadable(
  lazy(() => lazyRetry(() => import('../views/tokens/TokenView'))),
);

/* ****User Profile***** */
const UserProfile = Loadable(
  lazy(() => lazyRetry(() => import('../views/user-profile/UserProfile'))),
);
const UserNewPassword = Loadable(
  lazy(() => lazyRetry(() => import('../views/user-profile/UserNewPassword'))),
);
const CompanyProfile = Loadable(
  lazy(() => lazyRetry(() => import('../views/user-profile/CompanyProfile'))),
);
const CompanyUser = Loadable(
  lazy(() =>
    lazyRetry(() => import('../views/user-profile/CompanyUserUpdate')),
  ),
);
const CompanyUserCreate = Loadable(
  lazy(() =>
    lazyRetry(() => import('../views/user-profile/CompanyUserCreate')),
  ),
);
const NewUserPassword = Loadable(
  lazy(() => lazyRetry(() => import('../views/user-profile/NewUserPassword'))),
);

/* ****Billing***** */
const QuotesTable = Loadable(
  lazy(() => lazyRetry(() => import('../views/billing/QuotesTable'))),
);
const ContractsTable = Loadable(
  lazy(() => lazyRetry(() => import('../views/billing/ContractsTable'))),
);
const InvoicesTable = Loadable(
  lazy(() => lazyRetry(() => import('../views/billing/InvoicesTable'))),
);
const InvoiceView = Loadable(
  lazy(() => lazyRetry(() => import('../views/billing/InvoiceView'))),
);
const QuoteView = Loadable(
  lazy(() => lazyRetry(() => import('../views/billing/QuoteView'))),
);
const ContractView = Loadable(
  lazy(() => lazyRetry(() => import('../views/billing/ContractView'))),
);

/* ****Status Health***** */
const Status = Loadable(
  lazy(() => lazyRetry(() => import('../views/status/Status'))),
);

/* ****Routes***** */

const routes = [
  {
    path: '/',
    element: <FullLayout />,
    errorElement: <ErrorPopup />,
    children: [
      { path: '/', element: <Navigate to='/dashboard' /> },
      { path: '/dashboard', exact: true, element: <Dashboard /> },
      { path: '/widgets', element: <WidgetTable /> },
      { path: '/widgets/:id', element: <WidgetEdit /> },
      { path: '/tokens', element: <TokensTable /> },
      { path: '/tokens/:id', element: <TokenView /> },
      { path: '/reports/income_report/:id', element: <IncomeReportDetail /> },
      {
        path: '/reports/traffic_reports',
        exact: true,
        element: <TrafficReports />,
      },
      { path: '/reports/risk', exact: true, element: <RiskEvaluation /> },
      { path: '/reports/:type', exact: true, element: <Reports /> },
      { path: '/reports/logs', element: <LogsView /> },
      {
        path: '/reports/downloadables',
        exact: true,
        element: <Downloadables />,
      },
      { path: '/reports/summary', exact: true, element: <ResumeView /> },
      {
        path: '/reports/summary/generate',
        exact: true,
        element: <GenerateSummary />,
      },
      { path: '/report/:caseid', element: <ReportView /> },
      { path: '/report/download/:caseid', element: <DownloadReport /> },
      { path: '/billing/quotes', element: <QuotesTable /> },
      { path: '/billing/quote/:id', element: <QuoteView /> },
      { path: '/billing/invoices', element: <InvoicesTable /> },
      { path: '/billing/invoice/:id', element: <InvoiceView /> },
      { path: '/billing/contracts', element: <ContractsTable /> },
      { path: '/billing/contract/:id', element: <ContractView /> },
      { path: '/factoring', exact: true, element: <FactoringCompanies /> },
      {
        path: '/factoring/:id',
        exact: true,
        element: <FactoringCompanyView />,
      },
      { path: '/user-profile', element: <UserProfile /> },
      { path: '/new-password', element: <NewUserPassword /> },
      { path: '/settings/company', element: <CompanyProfile /> },
      { path: '/settings/company/user', element: <CompanyUser /> },
      { path: '/settings/company/user/create', element: <CompanyUserCreate /> },
      { path: '/settings/user', element: <UserProfile /> },
      { path: '/settings/user/new-password', element: <UserNewPassword /> },
      { path: '/payins/transactions', exact: true, element: <PaymentsView /> },
      { path: '/payins/widgets', exact: true, element: <PaymentsSettings /> },
      { path: '/payins/collections', exact: true, element: <PaymentLinks /> },
      {
        path: '/payins/collections/generate',
        exact: true,
        element: <GenerateCollection />,
      },
      {
        path: '/payins/widgets/:id',
        exact: true,
        element: <PaymentWidgetDetails />,
      },
      {
        path: '/payins/widgets/create',
        exact: true,
        element: <PaymentWidgetCreate />,
      },
      { path: '/payins/stats', exact: true, element: <PaymentStatsView /> },
      {
        path: '/payouts/transactions',
        exact: true,
        element: <PayoutsListView />,
      },
      {
        path: '/payouts/balance',
        exact: true,
        element: <PayoutsBalance />,
      },
      { path: '/status', exact: true, element: <Status /> },
      { path: '*', element: <Navigate to='/auth/404' /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/reset-password', element: <ResetPassword /> },
      { path: '/auth/new-password', element: <NewPassword /> },
      // { path: '/auth/new-password/:token', element: <NewPassword />},
      { path: '/auth/password-updated', element: <PasswordUpdated /> },
      { path: '/auth/mail-sent', element: <MailSent /> },
      { path: '/auth/quickdownload', element: <Register /> },
      { path: '*', element: <Navigate to='/auth/404' /> },
    ],
  },
  {
    // add single rout that does not need to be authenticated nor have a layout
    path: '/quickdownload',
    element: <BlankLayout />,
    children: [
      { path: '/quickdownload', element: <QuickDownload /> },
      { path: '/quickdownload/view', element: <QuickReportView /> },
      { path: '*', element: <Navigate to='/auth/404' /> },
    ],
  },
  {
    path: '/download',
    element: <BlankLayout />,
    children: [
      { path: '/download/report/:caseid', element: <ReportView /> },
      { path: '/download/billing/invoice/:id', element: <InvoiceView /> },
      { path: '/download/billing/quote/:id', element: <QuoteView /> },
      { path: '/download/billing/contract/:id', element: <ContractView /> },
    ],
  },
];

export default routes;
